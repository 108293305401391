.custom-carousel .carousel-indicators [data-bs-target] {
  background-color: #71adee;
}

.custom-carousel .carousel-indicators .active {
  background-color: #007bff; 
}

.custom-carousel .carousel-control-prev-icon,
.custom-carousel .carousel-control-next-icon {
  display: none;
}
